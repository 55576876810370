import styled, { keyframes } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { PAGE_TYPE } from 'Constants/constants';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

/* Styled Components */
const fadeInAnimation = ($finalTopValue: any) => keyframes`
	0% {
		top: 0;
		opacity: 0;
	}
	100% {
		top: ${$finalTopValue};
		opacity: 1;
	}
`;

export const StyledUniversalSearchStrip = styled.div<{ $leftLayout?: boolean }>`
	position: relative;
	top: ${({ $leftLayout }) => ($leftLayout ? '0' : '0.25rem')};
	z-index: 10;
	margin: 0 0 0.5rem;
	display: flex;
	justify-content: ${({ $leftLayout }) =>
		$leftLayout ? 'flex-start' : 'center'};
`;

export const StyledUniversalSearchWrapper = styled.div<{
	$leftLayout?: boolean;
	$isSearchFocused?: boolean;
}>`
	padding: 0;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	background: ${colors.GREY_DS.G8};
	border: 1px solid ${colors.GREY_DS.G6};
	height: ${({ $leftLayout }) => ($leftLayout ? '4rem' : '3rem')};
	width: 25.5rem;
	border-radius: 0.5rem;

	${({ $leftLayout }) =>
		$leftLayout &&
		`
		background: ${colors.WHITE};
		filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
	`}

	.search-helper-icon {
		width: 1.25rem;
		height: 1.25rem;
		position: absolute;
		right: 1rem;
		cursor: pointer;

		${({ $leftLayout }) =>
			$leftLayout &&
			`
        width: 1.5rem;
        height: 1.5rem;
        right: 1.25rem;
        `}
	}

	input {
		width: 100%;
		height: 100%;
		background: inherit;
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		padding: ${({ $leftLayout }) =>
			$leftLayout ? ' 0 1.25rem 0 1rem' : '0 1rem 0'};

		&::placeholder {
			color: ${colors.GREY_DS.G4};
		}

		&:focus {
			outline: none;
		}
	}

	${({ $isSearchFocused }) =>
		$isSearchFocused &&
		`
			background: ${colors.WHITE};
			border: 1px solid ${colors.WHITE};
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
                0px 2px 8px rgba(0, 0, 0, 0.1);
            width: 31.75rem;
            transition: width 0.2s ease-in-out;
            z-index: 1001;
    `}
`;

export const UniversalSearchResultsDropdown = styled.div<{
	$isSearchFocused?: boolean;
	$leftLayout?: boolean;
}>`
	position: absolute;
	top: 0;
	width: 31.75rem;
	border-radius: 0.5rem;
	background: ${colors.WHITE};
	z-index: ${({ $isSearchFocused }) => ($isSearchFocused ? 1000 : 99)};
	padding: 1rem 0 1rem 0;
	filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1))
		drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
	animation: ${({ $leftLayout }) =>
			fadeInAnimation($leftLayout ? '4.625rem' : '3.5rem')}
		0.4s ease-in-out forwards;
`;

export const SearchResultLinks = styled.a`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.375rem 1rem;

	&:hover,
	&.hovered {
		background: ${colors.GREY['F8']};
	}

	.search-item-container {
		display: flex;

		.search-text {
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
			color: ${colors.GREY_DS.G4};
		}

		.query-text {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			margin-left: 0.25rem;
			color: ${colors.GREY_DS.G2};
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM_HEAVY)};
		}
	}
`;

export const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100vh;
	z-index: 999;
`;
