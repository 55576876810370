import { useContext, useEffect, useRef, useState } from 'react';

import { AlgoliaSearchContext } from 'Contexts/algoliaSearchContext';
import { getSearchResultsForIndex } from 'Utils/algoliaUtils';
import { trackSearchSuggestionsViewed } from 'Utils/analytics';
import { debounce } from 'Utils/gen';
import { getSuggestionsLoadTime } from 'Utils/searchUtils';

import {
	SEARCH_DEBOUNCE_DELAY,
	SEARCH_INDEX_SETTINGS,
	SEARCH_LOCATION,
} from 'Constants/constants';

const useSearchResults = (searchQuery: string) => {
	const { citySearchIndex, productSearchIndex, collectionSearchIndex } =
		useContext(AlgoliaSearchContext);

	const prevSearchQuery = useRef(searchQuery);

	const [startTime, setStartTime] = useState(0);
	const [shouldTrack, setShouldTrack] = useState(false);

	const [isFetchingCityResults, setIsFetchingCityResults] = useState(false);
	const [isFetchingCollectionsResults, setIsFetchingCollectionsResults] =
		useState(false);
	const [isFetchingExperiencesResults, setIsFetchingExperiencesResults] =
		useState(false);
	const [topCitySearchCodeResults, setTopCitySearchCodeResults] = useState<
		Array<Object>
	>([]);
	const [topProductSearchCodeResults, setTopProductSearchCodeResults] =
		useState<Array<Object>>([]);
	const [
		topCollectionsSearchCodeResults,
		setTopCollectionsSearchCodeResults,
	] = useState<Array<Object>>([]);

	const isFetchingResults =
		isFetchingCityResults ||
		isFetchingExperiencesResults ||
		isFetchingCollectionsResults;

	const fetchSearchResults = () => {
		setStartTime(Date.now());
		setShouldTrack(true);

		getSearchResultsForIndex({
			searchIndex: citySearchIndex,
			query: searchQuery,
			indexSetting: SEARCH_INDEX_SETTINGS.CITY,
		}).then(cityHits => {
			setIsFetchingCityResults(false);
			setTopCitySearchCodeResults(cityHits);
		});

		getSearchResultsForIndex({
			searchIndex: productSearchIndex,
			query: searchQuery,
			indexSetting: SEARCH_INDEX_SETTINGS.PRODUCT,
		}).then(productHits => {
			setIsFetchingExperiencesResults(false);
			setTopProductSearchCodeResults(productHits);
		});

		getSearchResultsForIndex({
			searchIndex: collectionSearchIndex,
			query: searchQuery,
			indexSetting: SEARCH_INDEX_SETTINGS.COLLECTION,
		}).then(collectionHits => {
			setIsFetchingCollectionsResults(false);
			setTopCollectionsSearchCodeResults(collectionHits);
		});
	};

	useEffect(() => {
		const debouncedFetchSearchResults = debounce(
			fetchSearchResults,
			SEARCH_DEBOUNCE_DELAY,
		);

		if (searchQuery.length > 0) {
			setIsFetchingCityResults(true);
			setIsFetchingCollectionsResults(true);
			setIsFetchingExperiencesResults(true);

			// If query was empty before, fetch immediately
			!prevSearchQuery.current
				? fetchSearchResults()
				: debouncedFetchSearchResults();
		} else {
			setTopCitySearchCodeResults([]);
			setTopProductSearchCodeResults([]);
			setTopCollectionsSearchCodeResults([]);
		}

		prevSearchQuery.current = searchQuery;
		return () => {
			debouncedFetchSearchResults.cancel();
		};
	}, [searchQuery]);

	useEffect(() => {
		if (!isFetchingResults && searchQuery && shouldTrack) {
			trackSearchSuggestionsViewed({
				searchLocation: SEARCH_LOCATION.TOP,
				query: searchQuery,
				loadTime: getSuggestionsLoadTime(startTime),
				cityResultsLength: topCitySearchCodeResults.length,
				productResultsLength: topProductSearchCodeResults.length,
				collectionResultsLength: topCollectionsSearchCodeResults.length,
			});
			setShouldTrack(false);
		}
	}, [
		isFetchingResults,
		searchQuery,
		shouldTrack,
		startTime,
		topCitySearchCodeResults.length,
		topCollectionsSearchCodeResults.length,
		topProductSearchCodeResults.length,
	]);

	return {
		isFetchingResults,
		topCitySearchCodeResults,
		topProductSearchCodeResults,
		topCollectionsSearchCodeResults,
	};
};

export default useSearchResults;
