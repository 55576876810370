import { captureException as sendLogToSentry } from '@sentry/nextjs';
import type { SearchIndex } from 'algoliasearch/lite';

const getAlgoliaIndexSetting = (
	searchableAttribute: string[],
	hitsPerPage: number,
	isLocationSpecificSearchResults: boolean = false,
): {
	hitsPerPage: number;
	restrictSearchableAttributes: string[];
	typoTolerance: 'min' | 'strict' | boolean;
	aroundLatLngViaIP?: boolean;
	aroundRadius?: 'all';
} => ({
	hitsPerPage,
	restrictSearchableAttributes: searchableAttribute,
	typoTolerance: 'min',
	...(isLocationSpecificSearchResults && {
		aroundLatLngViaIP: true,
		aroundRadius: 'all',
	}),
});

type IndexSetting = {
	searchableAttributes: string[];
	hitsPerPage: number;
	isLocationSpecificSearchResults: boolean;
};

type SearchResultFunctionParams = {
	searchIndex: SearchIndex;
	query: string;
	indexSetting: IndexSetting;
};

export const getSearchResultsForIndex = async ({
	searchIndex,
	query,
	indexSetting,
}: SearchResultFunctionParams) => {
	try {
		const {
			searchableAttributes,
			hitsPerPage,
			isLocationSpecificSearchResults,
		} = indexSetting;
		const algoliaRequestOptions = getAlgoliaIndexSetting(
			searchableAttributes,
			hitsPerPage,
			isLocationSpecificSearchResults,
		);

		const { hits } = await searchIndex.search(query, algoliaRequestOptions);
		return hits;
	} catch (error) {
		sendLogToSentry(error);
		return [];
	}
};
