import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendLog } from 'Server/logger';

import { AlgoliaSearchContext } from 'Contexts/algoliaSearchContext';
import { getCloseByCities } from 'Utils/stateUtils';

import { receiveCloseByCities } from 'Actions/city';

type Props = {
	shouldFetchClosebyCities: boolean;
};

type SelectorState = {
	closeByCities: any[];
};

const useFetchCloseByCities = ({ shouldFetchClosebyCities }: Props) => {
	const dispatch = useDispatch();
	const { citySearchIndex } = useContext(AlgoliaSearchContext);
	const [hasFetchedResults, setHasFetchedResults] = useState(false);

	const { closeByCities }: SelectorState = useSelector(state => ({
		closeByCities: getCloseByCities(state) || [],
	}));

	const fetchCloseByCities = useCallback(async () => {
		try {
			const { hits }: { hits: any } = await citySearchIndex.search('', {
				aroundLatLngViaIP: true,
				aroundRadius: 'all',
				hitsPerPage: 5,
			});
			setHasFetchedResults(true);
			dispatch(receiveCloseByCities(hits));
		} catch (error) {
			sendLog({ err: error });
		}
	}, [citySearchIndex, dispatch]);

	useEffect(() => {
		if (hasFetchedResults || !shouldFetchClosebyCities) return;
		fetchCloseByCities();
	}, [fetchCloseByCities, hasFetchedResults, shouldFetchClosebyCities]);

	return { closeByCities };
};

export default useFetchCloseByCities;
