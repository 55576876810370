import type { MutableRefObject } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Router from 'next/router';

import Conditional from 'Components/common/conditional';
import LSpan from 'Components/common/localizedTags/localizedSpan';
import RenderOneOf from 'Components/common/renderOneOf';
import SearchPlaceholder from 'Components/common/searchPlaceholder';

import { CloseSVGIcon } from 'Assets/svg/feedPage';
import { SmallSearchIconSvg } from 'Assets/svg/feedPage/SmallSearchIconSvg';
import { Arrow } from 'Assets/svg/homePage';

import useFetchCloseByCities from 'Hooks/useFetchCloseByCities';
import useSearchResults from 'Hooks/useSearchResults';
import {
	handleSearchBarDropdownEvent,
	handleSearchResultsClick,
} from 'Utils/analytics';
import { scrollElementToBelowHeader } from 'Utils/gen';
import { getRecentEntitiesFromLocalStore } from 'Utils/localStorageUtils';
import { getCombinedSearchResults, getSearchPageUrl } from 'Utils/searchUtils';
import {
	getCitiesMap,
	getCurrentCity,
	getCurrentLanguageCode,
	getDomainConfig,
} from 'Utils/stateUtils';
import { isHomePageUrl } from 'Utils/urlUtils';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import {
	DESKTOP_HEADER_CLASSNAME,
	DESKTOP_HEADER_Z_INDEX,
	MAX_RESULTS_SEARCH_DROPDOWN,
	RECENTLY_VIEWED_ENTITIES,
	SEARCH_LOCATION,
	UNIVERSAL_SEARCH_ID,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import TYPE_LABELS from 'Static/typography/labels';

import { TProps, TReduxStateProps } from './interface';
import {
	ModalOverlay,
	SearchResultLinks,
	StyledUniversalSearchStrip,
	StyledUniversalSearchWrapper,
	UniversalSearchResultsDropdown,
} from './style';

const SearchResults = dynamic(() => import('Components/common/searchResults'), {
	ssr: false,
});
const NoSearchResults = dynamic(
	() => import('Components/common/noSearchResults'),
	{ ssr: false },
);

export const TOP_DESTINATIONS = 'Top Destinations';
export const RECENTLY_SEARCHED = 'Recently Searched';

const UniversalSearch = ({
	searchLocation,
	autoScrollOnFocus,
	location,
}: TProps) => {
	const { citiesMap, currentCity, showCitiesList, lang }: TReduxStateProps =
		useSelector(state => ({
			citiesMap: getCitiesMap(state),
			currentCity: getCurrentCity(state),
			lang: getCurrentLanguageCode(state),
			showCitiesList: getDomainConfig(state)?.['showCitiesList'],
		}));

	const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
	const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

	const [universalInputValue, setUniversalInputValue] = useState('');
	const [isUniversalSearchInputFocused, setIsUniversalSearchInputFocused] =
		useState(false);
	const [hoveredItem, setHoveredItem] = useState(0);
	const [shouldFetchClosebyCities, setShouldFetchClosebyCities] =
		useState(false);
	const {
		isFetchingResults,
		topCitySearchCodeResults,
		topProductSearchCodeResults,
		topCollectionsSearchCodeResults,
	} = useSearchResults(universalInputValue);

	const { closeByCities } = useFetchCloseByCities({
		shouldFetchClosebyCities,
	});

	const recentCitySearches = getRecentEntitiesFromLocalStore(
		RECENTLY_VIEWED_ENTITIES,
	);
	const searchPageUrl = getSearchPageUrl({
		lang,
		query: universalInputValue,
		currentCity,
	});
	const showCloseByCities =
		closeByCities?.length > 0 &&
		!universalInputValue &&
		!(recentCitySearches?.length > 0);
	const showRecentSearches =
		recentCitySearches?.length > 0 &&
		!showCloseByCities &&
		!universalInputValue;

	const onSearchFocus = () => {
		inputRef.current.focus();
		document.body.style.overflow = 'hidden';

		// Header was overlapping the modal because of the DOM structure.
		if (searchLocation === SEARCH_LOCATION.LEFT) {
			const header = document.getElementsByClassName(
				DESKTOP_HEADER_CLASSNAME,
			)?.[0] as HTMLDivElement;
			header.style.zIndex = '0';
		}
	};

	const onSearchFocusOut = useCallback(() => {
		const header = document.getElementsByClassName(
			DESKTOP_HEADER_CLASSNAME,
		)?.[0] as HTMLDivElement;
		document.body.style.overflow = 'auto';

		if (searchLocation === SEARCH_LOCATION.LEFT && header) {
			// header's default z-index value.
			header.style.zIndex = DESKTOP_HEADER_Z_INDEX.toString();
		}

		setIsUniversalSearchInputFocused(false);
		setUniversalInputValue('');
	}, [searchLocation]);

	const handleClearInput = () => {
		setUniversalInputValue('');
		inputRef.current.focus();
	};

	const handleUniversalSearchInput = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => setUniversalInputValue(event.target.value);

	const handleSearchFocus = () => {
		let initialState;
		const isHomePage = isHomePageUrl(location.pathname);

		if (autoScrollOnFocus) {
			scrollElementToBelowHeader(
				containerRef.current,
				isHomePage ? 150 : 5,
			);
		}
		if (showCloseByCities) {
			initialState = TOP_DESTINATIONS;
		} else if (showRecentSearches) {
			initialState = RECENTLY_SEARCHED;
		}

		handleSearchBarDropdownEvent({
			searchLocation,
			initialState,
			placement: searchLocation,
		});
		setIsUniversalSearchInputFocused(true);
		setShouldFetchClosebyCities(true);
		onSearchFocus();
	};

	const handleCloseByCities = () => setShouldFetchClosebyCities(true);

	const handleSearchButton = (searchPageUrl: string, isKeyboard = false) => {
		handleResultsClick({
			searchType: isKeyboard
				? ANALYTICS_PROPERTIES.ENTER_KEY
				: ANALYTICS_PROPERTIES.SEARCH_BUTTON,
		});
		if (searchPageUrl && universalInputValue) Router.push(searchPageUrl);
	};

	const handleResultsClick = ({
		searchType,
		rank,
		resultTitle,
	}: {
		rank?: number | null;
		searchType: string;
		resultTitle?: string | null;
	}) => {
		handleSearchResultsClick({
			searchType,
			rank,
			resultTitle,
			searchLocation,
			query: universalInputValue,
		});
	};

	const handleSearchPageLinkClick = () =>
		handleResultsClick({
			searchType: ANALYTICS_PROPERTIES.SEARCH_QUERY,
		});

	const handleKeyBoardKeys = (event: React.KeyboardEvent) => {
		const { key } = event;
		let newHoveredItem = hoveredItem;
		const searchDropdownItems = document.querySelectorAll(
			'.result-link-desktop',
		) as NodeListOf<HTMLDivElement>;
		const currentHoveredElement = document.querySelectorAll(
			'.result-link-desktop.hovered',
		) as NodeListOf<HTMLDivElement>;
		const hasResults = universalInputValue
			? topCitySearchCodeResults.length > 0 ||
			  topCollectionsSearchCodeResults.length > 0 ||
			  topProductSearchCodeResults.length > 0
			: true;

		const getDropdownItem = (itemIndex: number) =>
			searchDropdownItems[itemIndex - 1 > 0 ? itemIndex - 1 : 0];

		const setHoveredItemSelectors = (newHoveredItem: number) => {
			if (newHoveredItem <= 0) return;

			const currentDropdownItem = getDropdownItem(newHoveredItem);
			if (currentHoveredElement?.length > 0) {
				currentHoveredElement[0].classList.remove('hovered');
			}
			currentDropdownItem.classList.add('hovered');
		};

		if (key === 'ArrowDown') {
			newHoveredItem =
				hoveredItem === searchDropdownItems.length
					? 1
					: hoveredItem + 1;
			setHoveredItemSelectors(newHoveredItem);
		} else if (key === 'ArrowUp') {
			newHoveredItem =
				hoveredItem === 1 || hoveredItem === 0
					? searchDropdownItems.length
					: hoveredItem - 1;
			setHoveredItemSelectors(newHoveredItem);
		} else if (key === 'Enter' && hasResults) {
			if (hoveredItem === 0) {
				handleSearchButton(searchPageUrl, true);
			} else {
				const currentDropdownItem = getDropdownItem(hoveredItem);
				currentDropdownItem?.click();
			}
		} else if (key === 'Escape' || key === 'Tab') {
			onSearchFocusOut();
		} else {
			handleSearchFocus();
		}

		setHoveredItem(newHoveredItem);
	};

	useEffect(() => {
		return () => {
			onSearchFocusOut();
		};
	}, [onSearchFocusOut]);

	const combinedSearchResults = getCombinedSearchResults({
		topCitySearchCodeResults,
		topProductSearchCodeResults,
		topCollectionsSearchCodeResults,
		universalInputValue,
		citiesMap,
		lang,
	});
	const showSearchResultsPageLink =
		universalInputValue && combinedSearchResults.length;
	const noResultsFound =
		universalInputValue &&
		!isFetchingResults &&
		!combinedSearchResults.length;
	const isLeftLayout = searchLocation === SEARCH_LOCATION.LEFT;

	if (!showCitiesList) return null;

	return (
		<StyledUniversalSearchStrip
			$leftLayout={isLeftLayout}
			ref={containerRef}
		>
			<Conditional if={isUniversalSearchInputFocused}>
				<ModalOverlay onClick={onSearchFocusOut} />
			</Conditional>
			<StyledUniversalSearchWrapper
				$isSearchFocused={isUniversalSearchInputFocused}
				$leftLayout={isLeftLayout}
			>
				<Conditional
					if={!isUniversalSearchInputFocused && !universalInputValue}
				>
					<SearchPlaceholder
						itemFontLabel={TYPE_LABELS.UI_LABEL_MEDIUM}
					/>
				</Conditional>
				<input
					id={UNIVERSAL_SEARCH_ID}
					type='search'
					autoComplete='off'
					value={universalInputValue}
					onChange={handleUniversalSearchInput}
					onFocus={handleSearchFocus}
					onKeyDown={handleKeyBoardKeys}
					onMouseEnter={handleCloseByCities}
					ref={inputRef}
					aria-label={strings.CITY_SEARCH_PAGE.HEADING}
				/>
				<RenderOneOf
					positionalConditions={[
						!universalInputValue,
						universalInputValue,
					]}
				>
					<SmallSearchIconSvg
						onClick={handleSearchFocus}
						className={'search-helper-icon'}
					/>
					<CloseSVGIcon
						onClick={handleClearInput}
						className={'search-helper-icon'}
					/>
				</RenderOneOf>
			</StyledUniversalSearchWrapper>

			<Conditional if={isUniversalSearchInputFocused}>
				<UniversalSearchResultsDropdown
					$leftLayout={isLeftLayout}
					$isSearchFocused={isUniversalSearchInputFocused}
				>
					<Conditional if={showCloseByCities}>
						<SearchResults
							title={strings.CITY_SEARCH_PAGE.CLOSE_CITIES}
							results={closeByCities}
							numberOfResults={3}
							isNearBy
							handleResultsClick={handleResultsClick}
						/>
					</Conditional>

					<Conditional if={showRecentSearches}>
						<SearchResults
							title={strings.CITY_SEARCH_PAGE.RECENT_SEARCHES}
							results={recentCitySearches}
							numberOfResults={5}
							isRecentSearches
							handleResultsClick={handleResultsClick}
						/>
					</Conditional>

					<Conditional if={universalInputValue.length}>
						<SearchResults
							title={strings.SEARCH_RESULTS}
							results={combinedSearchResults}
							numberOfResults={MAX_RESULTS_SEARCH_DROPDOWN}
							isSearchResults
							handleResultsClick={handleResultsClick}
						/>
					</Conditional>

					<Conditional if={showSearchResultsPageLink}>
						<Link href={searchPageUrl} passHref legacyBehavior>
							<SearchResultLinks
								className={'result-link-desktop'}
								onClick={handleSearchPageLinkClick}
							>
								<div className={'search-item-container'}>
									<LSpan className={'search-text'}>
										{strings.STICKY_FOOTER.HEADING.SEARCH}
									</LSpan>
									<LSpan className={'query-text'}>
										{universalInputValue}
									</LSpan>
								</div>
								<Arrow />
							</SearchResultLinks>
						</Link>
					</Conditional>

					<Conditional if={noResultsFound}>
						<NoSearchResults />
					</Conditional>
				</UniversalSearchResultsDropdown>
			</Conditional>
		</StyledUniversalSearchStrip>
	);
};

export default UniversalSearch;
